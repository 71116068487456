(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("bootstrap"));
	else if(typeof define === 'function' && define.amd)
		define("Lightbox", ["bootstrap"], factory);
	else if(typeof exports === 'object')
		exports["Lightbox"] = factory(require("bootstrap"));
	else
		root["Lightbox"] = factory(root["bootstrap"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__988__) {
return 